import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://devinthorpe.com/"
      }}>{`Devin Thorpe`}</a>{`, a "Champion of Social Good", has created an excellent interview series called `}<a parentName="p" {...{
        "href": "http://yourmarkontheworld.com/"
      }}>{`Your Mark on the World`}</a>{`, highlighting the efforts of individuals and companies who are trying to make a positive impact. Arlyn and I are honored that he selected PayPerks as a company to be interviewed on his live streamed show.`}</p>
    <p>{`You can watch our interview from earlier today below or see Devin's comments on his site `}<a parentName="p" {...{
        "href": "http://yourmarkontheworld.com/post/95125474494/new-company-expands-financial-inclusion"
      }}>{`here`}</a>{`, download the audio podcast `}<a parentName="p" {...{
        "href": "http://yourmarkontheworld.podomatic.com/entry/2014-08-27T04_00_00-07_00"
      }}>{`here`}</a>{`, or subscribe on iTunes `}<a parentName="p" {...{
        "href": "http://bit.ly/ymotwitunes"
      }}>{`here`}</a>{`.`}</p>
    <div className="vid-player-frame"><iframe width="640" height="390" src="//www.youtube.com/embed/R8Khn6MQHI8?rel=0" frameBorder="0" allowFullScreen></iframe></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      